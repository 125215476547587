<script setup>
import { ref } from "vue";
import {
  add,
  subtract,
  singleLine,
  multiLine,
  fileUpload,
  list,
  select,
  page,
  table,
} from "../../svgs.js";
import compoentSchema from "../../form-components.js";

const components = [
  {
    name: "single-line",
    icon: singleLine,
    type: "input",
  },
  {
    name: "Sensitive",
    icon: singleLine,
    type: "sensitive",
  },
  {
    name: "multi-line",
    icon: multiLine,
    type: "multi-line-input",
  },
  {
    name: "Content",
    icon: singleLine,
    type: "content",
  },
  {
    name: "Arithmetic",
    icon: singleLine,
    type: "arithmetic",
  },
  {
    name: "file upload",
    icon: fileUpload,
    type: "file",
  },
  {
    name: "Signature",
    icon: fileUpload,
    type: "signature",
  },
  {
    name: "select",
    icon: select,
    type: "select",
  },
  {
    name: "Checklist",
    icon: select,
    type: "checklist",
  },
  {
    name: "Data",
    icon: select,
    type: "data",
  },
  {
    name: "Table",
    icon: table,
    type: "table",
  },
  {
    name: "Range ",
    icon: singleLine,
    type: "range",
  },
  {
    name: "list",
    icon: list,
    type: "list",
  },
  {
    name: "Page",
    icon: page,
    type: "group",
  },
];

const emit = defineEmits({
  addComponent: (component) => {
    return component;
  },
});

const randomAlphabet = () => {
  const [min, max] = [1, 5]; // random length of alphabets
  const alphabets = "abcdefghijklmnopqrstuvwxyz";
  const randomLength = Math.floor(Math.random() * (max - min + 1)) + min;
  let result = "";
  for (let i = 0; i < randomLength; i++) {
    result += alphabets.charAt(Math.floor(Math.random() * alphabets.length));
  }

  return result;
};

const randomNumber = () => {
  return Math.floor(Math.random() * 4500);
};

const addComponent = (component) => {
  if (component.type === "group") {
    return emit("addComponent", {
      type: "group",
      properties: [],
    });
  }

  const cc = compoentSchema.find((c) => c.type === component.type);
  // if we want key and label to be uniquely generated
  const id = `${randomAlphabet()}${Date.now()}${randomNumber()}`;
  const key = `content-key-${randomAlphabet()}${Date.now()}${randomNumber()}`;
  const label = `content-label-${randomAlphabet()}${Date.now()}${randomNumber()}`;
  const newComponent = {
    ...cc,
    id: cc.id === "@id" ? id : cc.id,
    key: cc.key === "@key" ? key : cc.key,
    label: cc.label === "@label" ? label : cc.label,
  };

  isActive.value = false;
  emit("addComponent", newComponent);
};

const isActive = ref(false);
</script>
<template>
  <div class="hyphen-form-builder-add-new-component">
    <div class="add-component-btn-wrapper">
      <button
        id="add-component"
        v-html="!isActive ? add : subtract"
        @click="isActive = !isActive"
      ></button>
    </div>
    <div
      class="components-list"
      :class="{
        'components-list--active': isActive,
      }"
    >
      <div
        v-for="component in components"
        :key="component.key"
        class="components-list__component"
        @click="addComponent(component)"
      >
        <span class="c-icon" v-html="component.icon"></span>
        <span class="c-name">{{ component.name }}</span>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

.hyphen-form-builder-add-new-component {
  .add-component-btn-wrapper {
    border-bottom: 1.5px dashed #d9dee1;
    margin: 40px 0px;

    #add-component {
      height: 42px;
      width: 42px;
      border-radius: 50%;
      cursor: pointer;
      border: none;
      outline: none;
      background-color: var(--primary-color, #19283d);
      display: flex;
      margin: auto;
      justify-content: center;
      align-items: center;
      transform: translateY(50%);
    }
  }

  .components-list {
    margin-top: 30px;
    gap: 10px;
    opacity: 0;
    height: 0px;
    max-height: 0px;
    overflow: hidden;
    transition: all 0.4s ease-in-out;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));

    &--active {
      height: auto;
      max-height: 300px;
      overflow: auto;
      opacity: 1;
    }

    &__component {
      flex: 1 1 25%;
      display: inline-flex;
      align-items: center;
      gap: 10px;
      border-radius: 5px;
      background: #f0f2f3;
      padding: 10px;
      cursor: pointer;

      .c-icon {
        height: 40px;
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        background: #fff;
      }

      .c-name {
        color: var(--hyphen-Blue, #19283d);
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
  }
}
</style>
