<script setup>
import { defineProps } from "vue";
import InputDash from "./InputDash.vue";
import RangeDash from "./RangeDash.vue";
import ArithmeticDash from "./ArithmeticDash.vue";
import ContentDash from "./ContentDash.vue";
import FileDash from "./FileDash.vue";
import SignatureDash from "./SignatureDash.vue";
import SelectDash from "./SelectDash.vue";
import CheckListDash from "./CheckListDash.vue";
import DataDash from "./DataDash.vue";
import TableDash from "./TableDash.vue";
import listDash from "./listDash.vue";
import MultiInputDash from "./MultiInputDash.vue";
import SensitiveDash from "./SensitiveDash.vue";

const props = defineProps({
  data: {
    type: Object,
    default: null,
  },
  stream: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits({
  edit(val) {
    return val;
  },
  remove(val) {
    return val;
  },
  cancelEdit(val) {
    return val;
  },
});

const edit = (val) => {
  emit("edit", val);
};
</script>
<template>
  <div class="hyphen-form-builder-type-dash" v-if="props && props.data">
    <input-dash
      v-if="props.data.type === 'input'"
      @edit="edit"
      @remove="emit('remove', props.data)"
      @cancelEdit="emit('cancelEdit', props.data)"
      :form="props.data"
    />

    <range-dash
      v-if="props.data.type === 'range'"
      @edit="edit"
      @remove="emit('remove', props.data)"
      @cancelEdit="emit('cancelEdit', props.data)"
      :form="props.data"
    />

    <arithmetic-dash
      v-if="props.data.type === 'arithmetic'"
      @edit="edit"
      @remove="emit('remove', props.data)"
      @cancelEdit="emit('cancelEdit', props.data)"
      :form="props.data"
      :stream="props.stream"
    />

    <sensitive-dash
      v-if="props.data.type === 'sensitive'"
      @edit="edit"
      @remove="emit('remove', props.data)"
      @cancelEdit="emit('cancelEdit', props.data)"
      :form="props.data"
    />

    <multi-input-dash
      v-if="props.data.type === 'multi-line-input'"
      @edit="edit"
      @remove="emit('remove', props.data)"
      @cancelEdit="emit('cancelEdit', props.data)"
      :form="props.data"
    />
    <content-dash
      v-if="props.data.type === 'content'"
      @edit="edit"
      @remove="emit('remove', props.data)"
      @cancelEdit="emit('cancelEdit', props.data)"
      :form="props.data"
    />
    <file-dash
      v-if="props.data.type === 'file'"
      @edit="edit"
      @remove="emit('remove', props.data)"
      @cancelEdit="emit('cancelEdit', props.data)"
      :form="props.data"
    />
    <signature-dash
      v-if="props.data.type === 'signature'"
      @edit="edit"
      @remove="emit('remove', props.data)"
      @cancelEdit="emit('cancelEdit', props.data)"
      :form="props.data"
    />

    <select-dash
      v-if="props.data.type === 'select'"
      @edit="edit"
      @remove="emit('remove', props.data)"
      @cancelEdit="emit('cancelEdit', props.data)"
      :form="props.data"
    />

    <check-list-dash
      v-if="props.data.type === 'checklist'"
      @edit="edit"
      @remove="emit('remove', props.data)"
      @cancelEdit="emit('cancelEdit', props.data)"
      :form="props.data"
    />

    <data-dash
      v-if="props.data.type === 'data'"
      @edit="edit"
      @remove="emit('remove', props.data)"
      @cancelEdit="emit('cancelEdit', props.data)"
      :form="props.data"
    />

    <table-dash
      v-if="props.data.type === 'table'"
      @edit="edit"
      @remove="emit('remove', props.data)"
      @cancelEdit="emit('cancelEdit', props.data)"
      :form="props.data"
    />

    <list-dash
      v-if="props.data.type === 'list'"
      @edit="edit"
      @remove="emit('remove', props.data)"
      @cancelEdit="emit('cancelEdit', props.data)"
      :form="props.data"
    />
  </div>
</template>
<style lang="scss" scoped>
.hyphen-form-builder-type-dash {
  height: 100%;
  width: 100%;
}
</style>
