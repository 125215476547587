<script setup>
import draggable from "vuedraggable";
import { computed, ref, watch, watchEffect } from "vue";
import HyphenSideDrawer from "../base/HyphenSideDrawer.vue";
import IndexDash from "./TypeDash/IndexDash.vue";
import AddNewComponent from "./AddNewComponent.vue";
import FormBuilderRow from "./FormBuilderRow.vue";
import FormBuilderWrapper from "./FormBuilderWrapper.vue";
import FormRender from "../Render/FormRender.vue";
import compoentSchema from "../../form-components.js";

const props = defineProps({
  showPreview: {
    type: Boolean,
    default: false,
  },
  details: {
    type: Object,
    default: () => {},
  },
  preSchema: {
    type: [Object, Array, String],
    default: () => {},
  },

  hyphenToken: {
    type: String,
    default: "",
  },
  hyphenBaseUrl: {
    type: String,
    default: "https://api.onpbot.com/v1",
  },
  formId: {
    type: String,
    default: "",
  },
});

const emit = defineEmits({
  canPreview(val) {
    return val;
  },
  schema(val) {
    return val;
  },
  fields(val) {
    return val;
  },
  flatStructure(val) {
    return val;
  },
});

const hasInjectedDefault = ref(false);

const sendOut = (value) => {
  emit("canPreview", value);
  if (value) {
    emit("schema", formStructure.value);
    emit("fields", fieldsName.value);
    emit("flatStructure", flatStructure.value);
  }
};

const wrapperKey = ref(0);

const randomAlphabet = () => {
  const [min, max] = [1, 5]; // random length of alphabets
  const alphabets = "abcdefghijklmnopqrstuvwxyz";
  const randomLength = Math.floor(Math.random() * (max - min + 1)) + min;
  let result = "";
  for (let i = 0; i < randomLength; i++) {
    result += alphabets.charAt(Math.floor(Math.random() * alphabets.length));
  }

  return result;
};

const randomNumber = () => {
  return Math.floor(Math.random() * 4500);
};

const uniqueId = () => {
  return `${randomAlphabet()}${Date.now()}${randomNumber()}`;
};

const injectComponent = (component) => {
  if (typeof component === "object") {
    const index = compoentSchema.findIndex((c) => c.type === component.type);
    if (index > -1) {
      // if it is an object with component name and id property
      formStructure.value[currentPage.value].properties.push(
        component.id === "@" || !component.id || component.id === ""
          ? {
              ...component,
              id: uniqueId(),
              key: getKey(component.label),
            }
          : {
              ...component,
              key: getKey(component.label),
            }
      );
    }
  } else {
    // if it just the component name
    const index = compoentSchema.findIndex((c) => c.type === component);
    if (index > -1) {
      formStructure.value[currentPage.value].properties.push({
        ...compoentSchema[index],
        id: uniqueId(),
      });
    }
  }
};

const getKey = (value) => {
  return value
    .toLowerCase()
    .replace(/ /g, "_")
    .replace(/[^\w-]+/g, "");
};

const sideDashboard = ref(false);

const selectedComponent = ref(null);

const formStructure = ref([
  {
    type: "group",
    properties: [],
  },
]);

const fieldsName = computed(() => {
  return flatStructure.value.map((item) => {
    return {
      id: item.id,
      type: item.type,
      label: item.label,
      key: item.key,
      value: item.value,
      required: item.required,
    };
  });
});

const flatStructure = computed(() => {
  const data = [];
  formStructure.value.map((item, index) => {
    if (item.type === "group" && item.properties.length > 0) {
      item.properties.map((i) => {
        data.push({
          ...i,
          groupIndex: index,
        });
      });
    }
  });

  return data;
});

const stream = computed(() => {
  return flatStructure.value;
});

const currentPage = ref(0);

// if next page is available
const hasNextPage = computed(() => {
  if (!Array.isArray(formStructure.value)) return false;
  return formStructure.value.length > currentPage.value + 1;
});

// if previous page is available
const hasPreviousPage = computed(() => {
  if (!Array.isArray(formStructure.value)) return false;
  return currentPage.value > 0;
});

const pageIsEmpty = computed(() => {
  if (!Array.isArray(formStructure.value)) return false;
  return formStructure.value[currentPage.value].properties.length === 0;
});

// get the current page been viewed
const pageView = computed(() => {
  if (!Array.isArray(formStructure.value)) return [];
  return formStructure.value[currentPage.value];
});

// move to the next or previous page
const movePage = (direction) => {
  if (canRemovePage()) return;

  if (direction === "next") {
    currentPage.value = currentPage.value + 1;
  } else {
    currentPage.value = currentPage.value - 1;
  }
};

const canRemovePage = () => {
  if (
    formStructure.value[currentPage.value].properties.length === 0 &&
    currentPage.value > 0
  ) {
    formStructure.value.splice(currentPage.value, 1);
    currentPage.value = currentPage.value - 1;
    return true;
  }

  return false;
};

// add component to the current page [currentPage.value]
const addComponent = (component) => {
  // if type is group add it to raw structure
  if (component.type === "group") {
    const insertIndex = currentPage.value + 1;
    formStructure.value.splice(insertIndex, 0, component);
    currentPage.value = insertIndex;
    return;
  }

  if (component.type !== "group") {
    formStructure.value[currentPage.value].properties.push(component);
    selectedComponent.value = { ...component };
    sideDashboard.value = true;
  }
};

// remove component from the current page [currentPage.value]
const removeComponent = (component) => {
  const index = formStructure.value[currentPage.value].properties.findIndex(
    (item) => item.id === component.id
  );
  // remove the component from the current page
  formStructure.value[currentPage.value].properties.splice(index, 1);

  // if the current page is empty and there are more than one page
  const canDelete =
    formStructure.value[currentPage.value].properties.length === 0 &&
    formStructure.value.length > 1;

  if (canDelete && hasPreviousPage.value) {
    formStructure.value.splice(currentPage.value, 1);
    currentPage.value = currentPage.value - 1;
  } else if (canDelete && !hasPreviousPage.value) {
    formStructure.value.splice(currentPage.value, 1);
    currentPage.value = 0;
  }

  sideDashboard.value = false;
  wrapperKey.value = Date.now();
};

const editC = (component) => {
  selectedComponent.value = { ...component };
  sideDashboard.value = component.locked ? false : true;
};

// update compoent in the current page [currentPage.value]
const editUpdate = (component) => {
  const index = formStructure.value[currentPage.value].properties.findIndex(
    (item) => item.id === component.id
  );
  formStructure.value[currentPage.value].properties.splice(index, 1, component);
  sideDashboard.value = false;
  wrapperKey.value = Date.now(); // force update of component
};

const allPagesAreFilled = computed(() => {
  if (!Array.isArray(formStructure.value)) return false;
  return formStructure.value.every((item) => item.properties.length > 0);
});

watch(
  () => allPagesAreFilled.value,
  (val) => {
    sendOut(val);
  },
  { deep: true },
  { immediate: true }
);

watchEffect(() => {
  if (process.env.NODE_ENV !== "production") {
    console.log("all pages are filled", allPagesAreFilled.value);
    console.log("form structure", JSON.stringify(formStructure.value, null, 2));
    console.log("flat structure", JSON.stringify(flatStructure.value, null, 2));
    console.log("fields name", JSON.stringify(fieldsName.value, null, 2));
  }
});

// when side dashboard is closed remove the selected component
watch(
  () => sideDashboard.value,
  (val) => {
    if (!val) {
      selectedComponent.value = null;
    }
  },
  { deep: true }
);

// when the preSchema changes inject the default components based on the type
// be it an array or object or string
watch(
  () => props.preSchema,
  (val) => {
    if (!hasInjectedDefault.value) {
      if (Array.isArray(val) && val.length > 0) {
        formStructure.value = [];
        val.map((item) => {
          if (item.type === "group") {
            formStructure.value.push(item);
          } else {
            injectComponent(item);
          }
        });
        hasInjectedDefault.value = true;
        return;
      }

      // if a type group object is passed
      if (typeof val === "object" && val.type === "group") {
        formStructure.value[currentPage.value].properties = val.properties;
        hasInjectedDefault.value = true;
        return;
      }

      if (typeof val === "object") {
        injectComponent(val);
        hasInjectedDefault.value = true;
        return;
      }

      if (typeof val === "string") {
        injectComponent(val);
        hasInjectedDefault.value = true;
        return;
      }
    }
  },
  { immediate: true }
);

const forRender = computed(() => {
  return formStructure.value;
});

watch(
  () => props.hyphenToken,
  (val) => {
    if (val) {
      sessionStorage.setItem("hyphenToken", val);
    }
  },
  { immediate: true }
);

watch(
  () => props.hyphenBaseUrl,
  (val) => {
    if (val) {
      sessionStorage.setItem("hyphenBaseUrl", val);
    }
  },
  { immediate: true }
);

watch(
  () => props.formId,
  (val) => {
    if (val) {
      sessionStorage.setItem("formId", val);
    }
  },
  { immediate: true }
);
</script>
<template>
  <div style="display: flex; width: 100%">
    <div v-if="!props.showPreview" class="hyphen-form-builder">
      <div class="hyphen-form-builder__header">
        <h3>
          {{ props.details.description || " N/A" }}
        </h3>
        <h1>{{ props.details.name || "N/A" }}</h1>
      </div>

      <form-builder-wrapper :key="wrapperKey">
        <div class="hyphen-form-builder__content">
          <draggable v-model="pageView.properties">
            <transition-group>
              <form-builder-row
                v-for="item in pageView.properties"
                :data="item"
                :key="item.id"
                :stream="stream"
                :is-selected="
                  selectedComponent && selectedComponent.id === item.id
                "
                @edit="editC(item)"
                @remove="removeComponent(item)"
              />
            </transition-group>
          </draggable>

          <div
            class="empty-pager"
            v-if="pageIsEmpty && currentPage !== 0"
          ></div>

          <div class="hyphen-form-builder__action">
            <span v-if="!hasPreviousPage"></span>
            <button v-if="hasPreviousPage" @click="movePage('previous')">
              Previous
            </button>
            <button v-if="hasNextPage" @click="movePage('next')">Next</button>
          </div>
        </div>
      </form-builder-wrapper>

      <add-new-component
        v-if="!props.showPreview"
        @addComponent="addComponent"
      />

      <HyphenSideDrawer v-model="sideDashboard" :closeable="false">
        <div class="hyphen-form-builder__dashboard">
          <index-dash
            v-if="selectedComponent"
            @edit="editUpdate"
            :stream="stream"
            @cancelEdit="sideDashboard = false"
            @remove="removeComponent"
            :data="selectedComponent"
          />
        </div>
      </HyphenSideDrawer>
    </div>
    <template v-else>
      <form-render
        v-if="forRender"
        is-preview
        :details="details"
        :schema="forRender"
        :should-verify="false"
        :hyphen-token="props.hyphenToken"
        :hyphen-base-url="props.hyphenBaseUrl"
        :formId="props.formId"
      />
    </template>
  </div>
</template>
<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

.hyphen-form-builder {
  width: 100%;
  padding: 35px;
  font-family: Inter;

  @media (max-width: 768px) {
    padding: 20px;
  }

  &__header {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 30px;

    h3 {
      color: var(--body-text, rgba(25, 40, 61, 0.8));
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: auto;
      margin: 0px;
      padding: 0px;
      display: block;
      margin-bottom: 15px;
    }

    h1 {
      font-size: 24px;
      font-weight: 700;
      color: #19283d;
      line-height: auto;
      margin: 0px;
      padding: 0px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 25px;

    .empty-pager {
      height: 100px;
    }
  }

  &__dashboard {
    padding: 20px;
    height: 100vh;
  }

  &__action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    margin-top: 25px;

    button {
      padding: 10px 30px;
      border-radius: 4px;
      border: none;
      background-color: #19283d;
      color: #fff;
      font-size: 16px;
      font-weight: 500;
      cursor: pointer;

      &[disabled] {
        opacity: 0.5;
        cursor: not-allowed;
      }

      &[loading] {
        // pulse animation
        animation: pulse 1.5s infinite;

        @keyframes pulse {
          0% {
            opacity: 0.5;
          }
          50% {
            opacity: 1;
          }
          100% {
            opacity: 0.5;
          }
        }
      }
    }
  }
}
</style>
